<template>
  <v-container fluid>
    <v-card elevation="0" outlined>
      <v-card-title class="card-header">
        <span>{{ this.stockTakingName }}</span>
        <v-spacer/>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn @click="$router.replace('/erp/storage/stockTaking/details/' + $route.params.id)"
                   color="primary"
                   icon large v-on="on">
              <v-icon large>remove_red_eye</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('erp.lang_create_ware_more') }}</span>
        </v-tooltip>
      </v-card-title>
      <v-divider class="pa-0 ma-0"/>
      <v-progress-linear indeterminate v-if="detail_loader"/>
      <v-card-text>
        <v-form lazy-validation ref="form">
          <v-row align="center" justify="center">
            <v-col class="mx-auto" cols="12" md="6" sm="8">
              <v-card-text>
                <v-row>
                  <v-col cols="12">
                    <v-select :disabled="this.detail_loader" clearable outlined :label="$t('generic.lang_waregroup')" v-model="group_id"
                              :items="this.groups"
                              item-text="name"
                              item-value="id"/>
                  </v-col>
                </v-row>

                <v-card flat color="grey" class="lighten-3">
                  <v-card-text>
                    <v-text-field style="margin-top: 20px;" :loading="isLoading" outlined :label="$t('erp.lang_posItemName')+', EAN'"
                                  :placeholder="$t('erp.lang_posItemName')+', EAN'"
                                  :data-layout="KEYBOARD.KEYSETS.NORMAL" v-model="search"></v-text-field>

                    <v-list v-if="entries && entries.length>0">
                      <v-alert color="info" dark v-if="!isLoading && entries.length === 0 && search !== null">{{ $t('generic.lang_noDataFound') }}</v-alert>

                      <v-list-item v-for="(item,key) in entries" :key="key+'-'+item.id" @click="setItem(item)">
                        <v-list-item-content v-if="!item.isVariation" v-text="item.name"></v-list-item-content>

                        <v-list-item-content v-else>
                          <v-list-item-title v-text="item.name"></v-list-item-title>
                          <v-list-item-subtitle>
                <span v-if="item.variationSize.length > 0">{{ $t('generic.lang_size') }}: {{
                    item.variationSize
                  }}</span> <br><span v-if="item.variationColor.length > 0">{{
                              $t('erp.lang_warecreate_color')
                            }}: {{ item.variationColor }}</span>
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-card-text>
                </v-card>

                <v-text-field style="margin-top: 25px;" :data-layout="KEYBOARD.KEYSETS.NORMAL" :disabled="this.selected == null"
                              @click:append="plus"
                              @click:prepend-inner="minus" @focus="showTouchKeyboard"
                              @keypress="isNumber($event)"
                              append-icon="add"
                              class="text-center mx-auto"
                              id="qt"
                              outlined
                              prepend-inner-icon="remove"
                              v-model.number="qt"
                />

                <span class="caption grey--text text--darken-1" v-if="this.clone != null">
                  <div>
                      <strong>{{ $t('erp.lang_posItemName') }} :</strong>
                      {{ this.clone.name }}</div>
                  <div>
                      <strong>Ean :</strong>
                      {{ this.clone.ean }}
                  </div>

                  <div>
                      <strong>{{ $t('erp.lang_invoiceUnitPrice') }} :</strong>
                      {{ this.clone.sellPrice }}
                  </div>
               </span>
              </v-card-text>

              <v-divider></v-divider>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    :disabled="(this.selected == null || this.loadingSelect)"
                    :loading="this.loadingSelect"
                    @click="switchWindow"
                    color="primary"
                    depressed
                >
                  {{ this.$t('generic.lang_add') }}
                </v-btn>
              </v-card-actions>
            </v-col>

            <v-col cols="12">

              <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
                <vue-touch-keyboard :accept="hideTouchKeyboard" :cancel="hideTouchKeyboard"
                                    :defaultKeySet="touchKeyboard.keySet"
                                    :input="touchKeyboard.input" :layout="touchKeyboard.layout"
                                    :options="touchKeyboard.options" class="internalWidthExpanded"
                                    id="onScreenKeyboard" v-if="touchKeyboard.visible"/>
              </div>
            </v-col>

          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>

//configs
import mixin from "../../../../mixins/KeyboardMixIns";
import {Events} from "@/plugins/events";
import {ENDPOINTS} from "@/config";

export default {
  name: "StockTakingScannerComponent",
  mixins: [mixin],
  data: () => {
    return {
      ENDPOINT: ENDPOINTS,
      stockTakingName: "",
      qt: 1,
      details: null,
      step: 1,
      group_id: null,
      isLoading: false,
      selected: null,

      entries: [],
      search: null,
      awaitingSearch: null,
      current: null,
      clone: null,

      loadingSelect: false,
      detail_loader: false,

      groups: [],
      items: []
    }
  },
  methods: {
    setItem(item){
      this.current = item;
      this.clone = item;
      this.search = null;
    },
    scan() {
      if (this.selected != null && this.current != null && this.selected.ean === this.current.ean) {
        this.$nextTick(() => {
          ++this.qt;
        });
      } else if (this.selected == null && this.current != null) {
        this.getQuantity();
      } else if(this.selected != null && this.current != null && this.selected.ean !== this.current.ean){
        this.qt=1;
        this.getQuantity();
      }
    },
    plus() {
      ++this.qt;
    },
    minus() {
      --this.qt;
    },
    switchWindow() {
      if (this.selected)
        this.update()
    },
    update() {
      this.loadingSelect = true;
      let quant = this.qt + this.selected.qt;
      this.axios.post(this.ENDPOINT.ERP.STOCKTAKING.UPDATE_QT,
          {
            stockTakingID: this.$route.params.id,
            wareID: this.selected.id,
            stockItemTakQTY: quant
          }).then((res) => {

        if (res.data.status === "SUCCESS") {
          Events.$emit("showSnackbar", {
            color: "success",
            message: this.$t('erp.stockTakingRecorded')
          });
          this.selected = null;
          this.qt = 1;
          --this.step;
        }


      }).catch((err) => {
        this.loadingSelect = false;
        Events.$emit("showSnackbar", {
          color: "error",
          message: err.message
        });
      }).finally(() => {
        this.loadingSelect = false;
      });
    },
    getQuantity() {
      this.loadingSelect = true;
      this.selected = Object.assign({}, this.current);

      this.axios.post(this.ENDPOINT.ERP.STOCKTAKING.QUANTITY,
          {
            stockTakingID: this.$route.params.id,
            wareID: this.selected.id
          }).then((res) => {

        if (res.data.status === "SUCCESS") {
          this.selected.qt = res.data.newStorageVal != null ? res.data.newStorageVal : 0;
          this.qt = 1;
        }
      }).catch((err) => {
        this.loadingSelect = false;
        Events.$emit("showSnackbar", {
          color: "error",
          message: err.message
        });
      }).finally(() => {
        this.loadingSelect = false;
        this.search = null;
      });
    },
    isNumber: function (evt) { // to accept only numbers
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    loadStockDetails() {
      this.detail_loader = true;
      this.axios.post(this.ENDPOINT.ERP.STOCKTAKING.SHOW,
          {
            stockTakingID: this.$route.params.id,
          }).then((res) => {

        if (res.data.formfillData) {
          this.stockTakingName = res.data.formfillData.textFields.stockTakingName;
        }
      }).catch((err) => {
        this.loadingSelect = false;
        Events.$emit("showSnackbar", {
          color: "error",
          message: err.message
        });
      }).finally(() => {
        this.detail_loader = false;
      })
    },
    //THIS FUNCTION IS FOR GETTING ITEMS FROM AVAILABLE ITEMS
    searchForItem(v) {
      if(v === null)
        return;

      if(v.length === 0)
        return;

      this.axios.post(ENDPOINTS.ERP.ITEM["SEARCHSTOCKTAKING"], {
        query: v,
        waregroupID: parseInt(this.group_id)
      }).then((res) => {
        this.entries.push(...res.data);
        this.isLoading = false;
      })
    },
  },

  async mounted() {
    this.items = await this.$store.dispatch("items/getItems");
    this.groups = await this.$store.dispatch("itemgroups/getItemgroups");

    this.loadStockDetails();
  },

  watch: {
    qt(v) {
      if (v.length == 0) this.qt = 0;
    },
    search(val) {
      if ((val && val.length === 0)) {
        return;
      }
      if(!val || val==='' )
        this.entries=[];

      clearTimeout(this.awaitingSearch);

      this.awaitingSearch = setTimeout(() => {
        this.searchForItem(val)
      }, 500);
    },
    current(v) {
      this.scan();
    }
  },
}
</script>

<style scoped>
input#qt {
  text-align: center !important;
  text-align: -webkit-center !important;
}
.v-list .v-list-item:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, .05);
}
</style>
