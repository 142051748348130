<template>
    <v-container class="pa-0" fluid>
        <page-title
                :heading="$t('erp.lang_ware_inventur') "
                :icon="icon"
                :subheading="$t('erp.lang_ware_inventur')"
                show-previous-button
                url-previous="/erp/storage/stockTaking"
        ></page-title>
        <div class="app-main__inner">
            <stock-taking-scanner-component />
        </div>
    </v-container>
</template>

<script>
    //components
    import PageTitle from "../../../../Layout/Components/PageTitle.vue";
    import StockTakingScannerComponent
        from "../../../../components/erp/storage/stocktaking/StockTakingScannerComponent";

    export default {
        components: {
            StockTakingScannerComponent,
            PageTitle,

        },
        data: () => ({
            icon: "pe-7s-box1 icon-gradient bg-tempting-azure"
        })
    };
</script>

<style>
</style>